import React from "react";
import "./SectionTwo.css";
import section_two_img_1 from "../../assets/images/section_two_img_1.png";
import {
  addImageAnimation,
  resetImageTransform,
} from "../../helpers/animationHelpers";

const SectionTwo = () => {
  return (
    <div className="SectionTwo" id="about-us">
      <div className="left">
        <div className="highlight">About us</div>
        <div className="heading">
          Trusted By Worldwide Clients Since <span>2019.</span>
        </div>
        <div className="paragraph">
          We have earned the trust of clients worldwide, establishing a
          reputation for reliability, integrity, and exceptional service.
        </div>
        <div className="list_items">
          <div className="list_item">
            <p className="icon">
              <i
                className="fa-sharp fa-solid fa-medal"
                style={{ rotate: "180deg" }}
              ></i>{" "}
            </p>
            <div>
              <h3>24*7 Support</h3>
              <p className="paragraph">
                Enjoy round-the-clock support for immediate assistance.
              </p>
            </div>
          </div>
          <div className="list_item">
            <p className="icon">
              <i
                className="fa-sharp fa-solid fa-medal"
                style={{ rotate: "180deg" }}
              ></i>{" "}
            </p>
            <div>
              <h3>Fast Solutions</h3>
              <p className="paragraph">
                Fast solutions tailored to meet your requirements seamlessly.
              </p>
            </div>
          </div>
          <div className="list_item">
            <p className="icon">
              <i
                className="fa-sharp fa-solid fa-medal"
                style={{ rotate: "180deg" }}
              ></i>{" "}
            </p>
            <div>
              <h3>Quality Control</h3>
              <p className="paragraph">
                Rigorous quality control ensures excellence in every aspect.
              </p>
            </div>
          </div>
          <div className="list_item">
            <p className="icon">
              <i
                className="fa-sharp fa-solid fa-medal"
                style={{ rotate: "180deg" }}
              ></i>{" "}
            </p>
            <div>
              <h3>Trusted Brand</h3>
              <p className="paragraph">
                A trusted company committed to your satisfaction and success.
              </p>
            </div>
          </div>
          <div className="btn_container">
            <button className="style_btn invert">Get In Touch</button>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="image_container">
          <img
            className="hoverChangePerspective"
            onMouseMove={addImageAnimation}
            onMouseLeave={resetImageTransform}
            src={section_two_img_1}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
