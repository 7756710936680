// Object's values
// {
//     "name": "123",
//     "email": "a@b.com",
//     "subject": "234342",
//     "message": "4234"
// }

import Swal from "sweetalert2";

export function formatContactUsData(object) {
  let obj = {};

  obj.subject = object.subject;
  obj.body = `Sender's Name : ${object.name}
  Sender's Email : ${object.email}
  
  Sender's Message :
  ${object.message}`;

  //   obj.body_html = "";
  return obj;
}

export const successSwal = (message) => {
  Swal.fire({
    title: "Success",
    text: message,
    icon: "success",
    confirmButtonText: "Close",
  });
};
export const loadingSwal = (message) => {
  Swal.fire({
    title: "Loading",
    text: message,
    icon: "info",
    // confirmButtonText: "Close",
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
};
export const failSwal = (message) => {
  Swal.fire({
    title: message,
    text: "Please try again",
    icon: "warning",
    confirmButtonText: "Close",
  });
};
