import React from "react";
import "./WorkWithUs.css";
import section_three_img_1 from "../../assets/images/section_three_img_1.png";
import {
  addImageAnimation,
  resetImageTransform,
} from "../../helpers/animationHelpers";

const WorkWithUs = () => {
  return (
    <div className="WorkWithUs">
      <div className="right">
        <div className="image_container">
          <img
            className="hoverChangePerspective"
            onMouseMove={addImageAnimation}
            onMouseLeave={resetImageTransform}
            src={section_three_img_1}
            alt=""
          />
        </div>
      </div>
      <div className="left">
        <div className="highlight">Why Choose Us</div>
        <div className="heading">
          Why Our Customers Choose <span> Working</span> With Us
        </div>
        <div className="paragraph">
          A single solution that allows automation of any business process and
          covers all the needs of the business, i.e., Odoo.
        </div>
        <div className="list_items">
          <div className="list_item">
            <p className="icon">01.</p>
            <div>
              <h3>App Development</h3>
              <p className="paragraph">
                Crafting innovative and user-centric mobile apps tailored to
                client needs.
              </p>
            </div>
          </div>
          <div className="list_item">
            <p className="icon">02.</p>
            <div>
              <h3>Web Development</h3>
              <p className="paragraph">
                Delivering cutting-edge web development solutions for seamless
                online experiences.
              </p>
            </div>
          </div>
          <div className="list_item">
            <p className="icon">03.</p>
            <div>
              <h3>Web Design</h3>
              <p className="paragraph">
                Transforming visions into reality with captivating and
                responsive web designs.
              </p>
            </div>
          </div>
          <div className="btn_container">
            <button className="style_btn invert">Get In Touch</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;
