import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import archiseto from "../assets/images/clients/archiseto.png";
import bella from "../assets/images/clients/bella.png";
import elauto from "../assets/images/clients/elauto.png";
import mexstark from "../assets/images/clients/mexstark.png";
import rykcsm from "../assets/images/clients/rykcsm.png";
import stark from "../assets/images/clients/stark.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";

// import required modules

export default function TrustedBySwiper() {
  const [slidesPerView, setSlidesPerView] = useState(
    window.innerWidth > 991 ? 6 : 3
  );

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(window.innerWidth > 991 ? 6 : 3);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // navigation={true}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="img_container">
            <img className="" src={archiseto} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img_container">
            <img className="" src={bella} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img_container">
            <img className="" src={elauto} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img_container">
            <img className="" src={mexstark} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img_container">
            <img className="" src={rykcsm} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="img_container">
            <img className="" src={stark} alt="" />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
