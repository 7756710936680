import React from "react";
import whiteArrow from "../assets/images/whiteArrow.png";
const PortfolioImageBox = ({ text, image }) => {
  return (
    <div className="image_box">
      <div className="background"></div>
      <img src={image} alt={text + "image"} />
      <div className="bottom_content">{text}</div>
      <button className="bottom_content-btn">
        <img src={whiteArrow} alt="" />
      </button>
    </div>
  );
};
export default PortfolioImageBox;
