import React, { useEffect, useState } from "react";
import "./Gallery.css";
import PageHeaders from "../../components/PageHeaders";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import gallery1 from "../../assets/images/Events/gallery1.jpeg";
import gallery2 from "../../assets/images/Events/gallery2.jpeg";
import gallery3 from "../../assets/images/Events/gallery3.jpeg";
import Footer from "../../Homepage/Footer/Footer";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";

const Gallery = () => {
  const [loading, setLoading] = useState(true);

  let images = [gallery1, gallery2, gallery3];

  let loading_duration = 3;
  let fade = 1;
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, (loading_duration + fade) * 1000);
  }, []);
  return (
    <>
      {loading && (
        <LoadingComponent loading_duration={loading_duration} fade={fade} />
      )}{" "}
      <div className="Gallery">
        <PageHeaders title="Gallery" route="Gallery" />
        <section>
          <div className="container">
            <div className="content">
              <div className="gallery_container">
                <div className="gallaries">
                  <h3 className="highlight" style={{ margin: "1rem auto" }}>
                    Saraswati Puja
                  </h3>
                  <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                    loop={true}
                  >
                    {images.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="gallery_image">
                            <img className="" src={item} alt="" />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                    {/* <SwiperSlide>Slide 1</SwiperSlide>
              <SwiperSlide>Slide 2</SwiperSlide>
              <SwiperSlide>Slide 3</SwiperSlide>
              <SwiperSlide>Slide 4</SwiperSlide>
              <SwiperSlide>Slide 5</SwiperSlide>
              <SwiperSlide>Slide 6</SwiperSlide>
              <SwiperSlide>Slide 7</SwiperSlide>
              <SwiperSlide>Slide 8</SwiperSlide>
              <SwiperSlide>Slide 9</SwiperSlide> */}
                  </Swiper>
                </div>
                <div className="gallaries">
                  <h3 className="highlight" style={{ margin: "1rem auto" }}>
                    OTHER EVENTS
                  </h3>
                  <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                    loop={true}
                  >
                    {images.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="gallery_image">
                            <img className="" src={item} alt="" />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                    {/* <SwiperSlide>Slide 1</SwiperSlide>
              <SwiperSlide>Slide 2</SwiperSlide>
              <SwiperSlide>Slide 3</SwiperSlide>
              <SwiperSlide>Slide 4</SwiperSlide>
              <SwiperSlide>Slide 5</SwiperSlide>
              <SwiperSlide>Slide 6</SwiperSlide>
              <SwiperSlide>Slide 7</SwiperSlide>
              <SwiperSlide>Slide 8</SwiperSlide>
              <SwiperSlide>Slide 9</SwiperSlide> */}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Gallery;

function Abcd() {
  return <img src={gallery1} />;
}
