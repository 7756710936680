import React, { useEffect, useRef, useState } from "react";
import "./ContactUs.css";
import { Link } from "react-router-dom";
import Footer from "../../Homepage/Footer/Footer";
import { formatContactUsData } from "../../helpers/helperFunctions";
import { sendContactUsFormData } from "../../controllers/rest";
import PageHeaders from "../../components/PageHeaders";
import Swal from "sweetalert2";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";

const ContactUs = () => {
  const [promiseLoading, setPromiseLoading] = useState(false);
  const [swalShown, setSwalShown] = useState(false);
  const [loading, setLoading] = useState(true);
  const formRef = useRef();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
  });

  const successSwal = () => {
    Swal.fire({
      title: "Success",
      text: "Your message has been successfully submitted",
      icon: "success",
      confirmButtonText: "Close",
    });
  };
  const loadingSwal = () => {
    Swal.fire({
      title: "Loading",
      text: "Your request is Loading, Please wait...",
      icon: "info",
      // confirmButtonText: "Close",
    });
  };
  const failSwal = () => {
    Swal.fire({
      title: "Something went wrong",
      text: "Please try again",
      icon: "Error",
      confirmButtonText: "Close",
    });
  };

  function changeFormData(event) {
    let target = event.target;

    let obj = {
      ...formData,
      [target.name]: target.value,
    };
    setFormData(obj);
  }

  function submitContactUsForm(event) {
    event.preventDefault();
    let data = formatContactUsData(formData);
    sendData(data);
  }

  async function sendData(data) {
    loadingSwal();

    try {
      setPromiseLoading(true);
      let res = await sendContactUsFormData(formRef);
      successSwal();
      setFormData({
        user_name: "",
        user_email: "",
        subject: "",
        message: "",
      });
      // console.log(res);
    } catch (err) {
      console.log(err);
      failSwal();
    }
  }

  let loading_duration = 3;
  let fade = 1;
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, (loading_duration + fade) * 1000);
  }, []);
  return (
    <>
      {" "}
      {loading && (
        <LoadingComponent loading_duration={loading_duration} fade={fade} />
      )}{" "}
      <div className="ContactUs">
        <PageHeaders title="Contact Us" route="Contact Us" />
        <section className="find-us">
          <div className="container">
            <div className="content">
              <div className="details">
                <h1 className="find-us-title">Find Us On Google Maps</h1>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14618.717252962279!2d87.2422681!3d23.651653!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f76b0066f418f3%3A0xe2d2b39e8b1628d8!2sINSABHI%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1712640421473!5m2!1sen!2sin"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Insabhi Location"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section className="questions">
          <div className="container">
            <div className="content">
              <div className="form_container">
                <h1>Have Any Questions? Let's Answer Them</h1>
                <form action="" onSubmit={submitContactUsForm} ref={formRef}>
                  <div className="name-email">
                    <label>
                      <div className="title required">Name</div>
                      <input
                        value={formData.user_name}
                        onChange={(e) => changeFormData(e)}
                        name="user_name"
                        required
                        type="text"
                      />
                    </label>
                    <label>
                      <div className="title required">Email</div>
                      <input
                        value={formData.user_email}
                        onChange={(e) => changeFormData(e)}
                        name="user_email"
                        required
                        type="email"
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      <div className="title required">Subject</div>
                      <input
                        value={formData.subject}
                        onChange={(e) => changeFormData(e)}
                        name="subject"
                        required
                        type="text"
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      <div className="title required">Your Message</div>
                      <textarea
                        required
                        type="text"
                        value={formData.message}
                        onChange={(e) => changeFormData(e)}
                        name="message"
                      />
                    </label>
                  </div>
                  <button type="submit" className="style_btn invert">
                    Send Your Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
