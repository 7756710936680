import React from "react";
import "./TrustedBy.css";
import TrustedBySwiper from "../../components/TrustedBySwiper";
const TrustedBy = () => {
  return (
    <section className="TrustedBy">
      <div className="container">
        <div className="content">
          <h2 className="heading">
            trusted by over 500 clients around the world
          </h2>
          <TrustedBySwiper />
        </div>
      </div>
    </section>
  );
};

export default TrustedBy;
