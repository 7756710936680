import React, { useState } from "react";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const LoadingComponent = ({ loading_duration, fade }) => {
  const black_ref_one = useRef();
  const black_ref_two = useRef();
  const loading_numberRef = useRef();
  const loading_ref = useRef();

  useEffect(() => {
    const black_one = black_ref_one.current;
    const black_two = black_ref_two.current;
    const loadingNumber = loading_numberRef.current;
    const loading = loading_ref.current;
    gsap.to(black_one, {
      left: "100%",
      duration: loading_duration,
    });
    gsap.to(black_two, {
      right: "100%",
      duration: loading_duration,
    });

    const tl = gsap.to(loadingNumber, {
      innerText: 100,
      duration: loading_duration,
      ease: "power1.inOut",
      onUpdate: () => {
        const currentValue = Math.floor(tl.progress() * 100);
        loadingNumber.innerText = currentValue + "%";

        const borderColor = `rgba(255, 255, 255, ${tl.progress() / 2})`;
        // Update the border color
        loadingNumber.style.borderBottom = `1px solid ${borderColor}`;
      },
    });
    gsap.to(loading, {
      opacity: 0,
      display: "none",
      duration: fade,
      delay: loading_duration,
    });

    return () => {
      // Clean up GSAP animation on component unmount
      tl.kill();
    };
  }, []);

  return (
    <>
      <div
        ref={loading_ref}
        className="loading"
        style={{ position: "fixed", top: "0", zIndex: "999" }}
      >
        <div ref={loading_numberRef} className="loading_number">
          0
        </div>
        <div ref={black_ref_one} className="black one"></div>
        <div ref={black_ref_two} className="black two"></div>
      </div>
    </>
  );
};

export default LoadingComponent;
