import React from "react";

const Socials = () => {
  return (
    <div className="socials">
      <div className="icons">
        <a href="https://www.facebook.com/insabhi24" target="_blank">
          <i className="fa-brands fa-facebook-f"></i>
        </a>
      </div>
      <div className="icons">
        <a href="https://in.pinterest.com/insabhip/" target="_blank">
          <i class="fa-brands fa-pinterest"></i>{" "}
        </a>
      </div>{" "}
      <div className="icons">
        <a href="https://www.instagram.com/insabhi24/" target="_blank">
          <i className="fa-brands fa-instagram"></i>{" "}
        </a>
      </div>
      <div className="icons">
        <a href="https://twitter.com/Insabhi24" target="_blank">
          <i className="fa-brands fa-twitter"></i>{" "}
        </a>
      </div>
    </div>
  );
};

export default Socials;
