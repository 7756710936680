import React, { useEffect, useRef } from "react";
import {
  addImageAnimation,
  resetImageTransform,
} from "../helpers/animationHelpers";
import gsap from "gsap";

const OverviewBox = ({ icon, number, text }) => {
  return (
    <div
      className="box_container hoverChangePerspective"
      onMouseMove={addImageAnimation}
      onMouseLeave={resetImageTransform}
    >
      <div className="box">
        <div className="icon">
          <img src={icon} alt="" />
        </div>
        <div className="number">{number}+</div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

export default OverviewBox;
