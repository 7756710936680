import React, { useEffect, useState } from "react";
import "./WorkWithUs.css";
import PageHeaders from "../../components/PageHeaders";
import Footer from "../../Homepage/Footer/Footer";
import WorkWithUsSwiper from "../../components/WorkWithUsSwiper";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";
import { careerSubmit } from "../../controllers/rest";
import Swal from "sweetalert2";
import {
  failSwal,
  loadingSwal,
  successSwal,
} from "../../helpers/helperFunctions";

const WorkWithUsPage = () => {
  async function submitContactUsForm(e) {
    e.preventDefault();
    loadingSwal("Your request is Loading, Please wait...");
    try {
      const formData = new FormData(e.target);
      const res = await careerSubmit(formData);
      if (res.data.success) {
        e.target.reset();
        successSwal("Your CV is Successfully Submitted");
      } else {
        failSwal("Something Went Wrong, Please try again");
      }
    } catch (e) {
      console.log(e);
      failSwal("Something Went Wrong, Please try again");
    }
  }
  let loading_duration = 3;
  let fade = 1;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, (loading_duration + fade) * 1000);
  }, []);

  return (
    <>
      {loading && (
        <LoadingComponent loading_duration={loading_duration} fade={fade} />
      )}{" "}
      <div className="WorkWithUsPage">
        <div>
          <PageHeaders title="Career" route="Career" />
        </div>
        <section className="swiper_container">
          <div className="container">
            <div className="content">
              <div>
                <div class="highlight">Technologies</div>
                <div class="heading">Technologies We Use</div>
              </div>
              <WorkWithUsSwiper />
            </div>
          </div>
        </section>
        <section className="questions">
          <div className="container">
            <div className="content">
              <div className="form_container">
                <h1>Send Your CV</h1>
                <form action="" onSubmit={submitContactUsForm}>
                  <div className="name-email">
                    <label>
                      <div className="title required">Name</div>
                      <input name="name" required type="text" />
                    </label>
                    <label>
                      <div className="title required">Email</div>
                      <input name="email" required type="email" />
                    </label>
                  </div>

                  <div>
                    <label>
                      <div className="title required">Your Resume</div>
                      <div className="input_container">
                        <input
                          required
                          type="file"
                          accept=".doc,.docx,application/msword,application/pdf"
                          name="cv"
                        />
                      </div>
                    </label>
                  </div>
                  <button type="submit" className="style_btn invert">
                    Submit Your Resume
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default WorkWithUsPage;
