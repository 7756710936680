import React from "react";
import PortfolioImageBox from "../../components/PortfolioImageBox";
import portfolioImage1 from "../../assets/images/portfolio/portfolioImage1.jpg";
import portfolioImage2 from "../../assets/images/portfolio/portfolioImage2.jpg";
import portfolioImage3 from "../../assets/images/portfolio/portfolioImage3.jpg";
import portfolioImage4 from "../../assets/images/portfolio/portfolioImage4.jpg";
import portfolioImage5 from "../../assets/images/portfolio/portfolioImage5.jpg";
import portfolioImage6 from "../../assets/images/portfolio/portfolioImage6.jpg";

import "./Portfolio.css";
const Portfolio = () => {
  return (
    <section className="Portfolio">
      <div className="container">
        <div className="content">
          <div className="text_content">
            <div className="highlight">Portfolio</div>
            <div className="flex">
              <div className="heading">Awesome Portfolio</div>
              <button className="style_btn invert">
                See More
                <span>
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </button>
            </div>
            <div className="options">
              <div className="option">All</div>
              <div className="option">Mobile Apps</div>
              <div className="option">Cloud</div>
              <div className="option">Data Analysis</div>
              <div className="option">Hosting</div>
            </div>
          </div>
          <div className="images_container">
            <div className="image_boxes">
              <PortfolioImageBox image={portfolioImage1} text="Mobile Apps" />
              <PortfolioImageBox image={portfolioImage2} text="Cloud" />
              <PortfolioImageBox image={portfolioImage3} text="Data Analysis" />
              <PortfolioImageBox image={portfolioImage4} text="Hosting" />
              <PortfolioImageBox image={portfolioImage5} text="SEO" />
              <PortfolioImageBox
                image={portfolioImage6}
                text="Other Categories"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
