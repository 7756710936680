import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Keyboard, Pagination, Navigation } from "swiper/modules";

import person1 from "../assets/images/persons/person1.png";
import person2 from "../assets/images/persons/person2.png";

export default function App() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Keyboard, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="swiper_container">
            <img src={person1} alt="" />
            <p className="comment">
              Working with Insabhi is refreshing. Their expertise and dedication
              are invaluable. They consistently demonstrate commitment as our
              reliable technology partner.
            </p>
            <div className="name">Samir Sal</div>
            <div className="post">Office Accountant</div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper_container">
            <img src={person2} alt="" />
            <p className="comment">
              Partnering with Sachin for 7+ years has been invaluable. He's
              nurtured a skilled team at Insabhi through personal training. I
              confidently recommend their dedication.
            </p>
            <div className="name">Santosh T</div>
            <div className="post">Owner</div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper_container">
            <img src={person2} alt="" />
            <p className="comment">
              Since 2018, Insabhi's work has been phenomenal, providing
              invaluable system enhancement recommendations. Sachin's talent and
              reliability make them highly professional and recommended.
            </p>
            <div className="name">Gurneet Jolly</div>
            <div className="post">Director</div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper_container">
            <img src={person1} alt="" />
            <p className="comment">
              Sachin showed professionalism developing our website on timely
              manner, he extended his support beyond our contact and supported
              us even at late hours when we needed him.
            </p>
            <div className="name">Mohammed Alkhatib</div>
            <div className="post">Owner</div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
