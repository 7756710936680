import { useEffect, useState } from "react";
import "./App.css";
import LoadingComponent from "./LoadingComponent/LoadingComponent";
import Pages from "./Pages/Pages";
import { BrowserRouter } from "react-router-dom";

function App() {
  const [loading, setLoading] = useState(true);

  return (
    <BrowserRouter>
      <div className="App">
        <Pages />
      </div>
    </BrowserRouter>
  );
}

export default App;
