import React from "react";
import { Link } from "react-router-dom";

const PageHeaders = ({ title, route }) => {
  return (
    <section className="page_header">
      <div className="container">
        <div className="background"></div>
        <div className="overlay"></div>
        <div className="content">
          <div className="page_details">
            <h1 className="title">{title}</h1>
            <div className="breadcrumbs">
              <Link to="/">Home</Link> <span>/ {route}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHeaders;
