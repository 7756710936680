import React, { useEffect, useRef } from "react";
import "./Services.css";
import ServiceBox from "../../components/ServiceBox";
import gsap from "gsap";

import globalBlue from "../../assets/images/globalBlue.png";
import profileBlue from "../../assets/images/profileBlue.png";
import searchBlue from "../../assets/images/searchBlue.png";
import strategyBlue from "../../assets/images/strategyBlue.png";
import webBlue from "../../assets/images/webBlue.png";
import webdevBlue from "../../assets/images/webdevBlue.png";

import globalWhite from "../../assets/images/globalWhite.png";
import profileWhite from "../../assets/images/profileWhite.png";
import searchWhite from "../../assets/images/searchWhite.png";
import strategyWhite from "../../assets/images/strategyWhite.png";
import webWhite from "../../assets/images/webWhite.png";
import webdevWhite from "../../assets/images/webdevWhite.png";

import { ScrollTrigger } from "gsap/ScrollTrigger";
const Services = () => {
  const grid_ref = useRef();

  const serviceHeadingRef = useRef();

  useEffect(() => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: serviceHeadingRef.current,
        start: "top 80%",

        // markers: true,
        stagger: 1,
      },
    });

    tl.fromTo(
      serviceHeadingRef.current,
      {
        top: "10vh",
        ease: "none", // Set easing to linear
        opacity: 0,
      },
      {
        top: "0",
        opacity: 1,
      }
    );
  }, []);
  return (
    <div className="Services">
      <div className="content">
        <div className="headline" ref={serviceHeadingRef}>
          <div>
            <div className="highlight">Services</div>
            <div className="heading">Services We Offer</div>
            <div className="paragraph">
              Experience our comprehensive solutions: Web development, Odoo,
              DevOps, App development, SEO, and AI services for your business
              success.
            </div>
          </div>
          <button className="style_btn invert">
            See All Services
            <span>
              <i className="fa-solid fa-arrow-right"></i>
            </span>
          </button>
        </div>
        <div className="grid">
          <ServiceBox
            icon="fa-solid fa-laptop-code"
            title="Web Development"
            paragraph="Craft visually appealing and interactive web applications using React, Angular, and Vue for a dynamic user experience."
            primaryIcon={webdevBlue}
            secondaryIcon={webdevWhite}
          ></ServiceBox>
          <ServiceBox
            icon="fa-solid fa-laptop-code"
            title="Odoo Development"
            paragraph="Develop robust and scalable business solutions on the Odoo platform, ensuring seamless integration and optimal performance."
            primaryIcon={globalBlue}
            secondaryIcon={globalWhite}
          ></ServiceBox>
          <ServiceBox
            icon="fa-solid fa-laptop-code"
            title="Dev Ops"
            paragraph="Implement CI/CD pipelines for efficient DevOps, facilitating continuous integration and deployment on diverse server environments."
            primaryIcon={webBlue}
            secondaryIcon={webWhite}
          />
          <ServiceBox
            icon="fa-solid fa-laptop-code"
            title="Apps Development"
            paragraph="Create cross-platform mobile apps with Flutter and React Native, delivering a native-like experience for diverse user bases."
            primaryIcon={profileBlue}
            secondaryIcon={profileWhite}
          />
          <ServiceBox
            icon="fa-solid fa-laptop-code"
            title="SEO Service"
            paragraph="Enhance online visibility and drive organic traffic through strategic SEO services, optimizing content and website structure for higher search engine rankings."
            primaryIcon={searchBlue}
            secondaryIcon={searchWhite}
          />
          <ServiceBox
            icon="fa-solid fa-laptop-code"
            title="Artificial Intelligence"
            paragraph="Leverage artificial intelligence to unlock data insights, automate processes, and provide innovative solutions for diverse business challenges."
            primaryIcon={strategyBlue}
            secondaryIcon={strategyWhite}
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
