import React, { useEffect, useRef } from "react";
import "./Testimonials.css";
import TestimonialsSwiper from "../../components/TestimonialsSwiper";
import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Testimonials = () => {
  let testi_ref = useRef();
  let bg_ref = useRef();

  return (
    <section className="Testimonials" ref={testi_ref}>
      <div className="container">
        <div className="background" ref={bg_ref}></div>
        <div className="content">
          <div className="testimonials_container">
            <div className="heading">
              <div className="highlight">Testimonials</div>
              <h2 className="heading_text">Customers Testimonials</h2>
            </div>
            <TestimonialsSwiper />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
