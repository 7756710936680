import React, { useEffect, useRef, useState } from "react";

import "./Homepage.css";
import image_01 from "../assets/images/image_1.png";
import {
  addImageAnimation,
  resetImageTransform,
} from "../helpers/animationHelpers";
import SectionTwo from "./SectionTwo/SectionTwo";
import WorkWithUs from "./WorkWithUs/WorkWithUs";
import Overview from "./Overview/Overview";
import Footer from "./Footer/Footer";
import Services from "./Services/Services";
import TrustedBy from "./TrustedBy/TrustedBy";
import Testimonials from "./Testimonials/Testimonials";
import Portfolio from "./Portfolio/Portfolio";
import Socials from "../components/Socials";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
const Homepage = () => {
  const image_ref = useRef();

  function openDialog() {}

  let loading_duration = 3;
  let fade = 1;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, (loading_duration + fade) * 1000);
  }, []);
  return (
    <>
      {loading && (
        <LoadingComponent loading_duration={loading_duration} fade={fade} />
      )}{" "}
      <div className="Homepage">
        <div className="overlay"></div>
        <section className="section_one">
          <div className="content">
            <Socials />
            <h3 className="highlight">it solutions</h3>
            <h1 className="heading">
              <div className="stripes"></div>
              We offer highly effective IT solutions for your{" "}
              <span>Success</span>
            </h1>
            <p className="paragraph">
              Insabhi is an Odoo ERP Solution and Software Development company.
            </p>
            <div className="btn_container">
              <button className="style_btn">Start Now</button>
              <button className="play_btn" onClick={openDialog}>
                <i className="fa-solid fa-play"></i>{" "}
              </button>
            </div>
          </div>
          <div className="right">
            <div className="image_container">
              <img
                className="hoverChangePerspective"
                onMouseMove={addImageAnimation}
                onMouseLeave={resetImageTransform}
                ref={image_ref}
                src={image_01}
                alt=""
              />
            </div>
          </div>
        </section>
        <>
          <Services />
          <SectionTwo />
          <WorkWithUs />
          <Overview />
          <Portfolio />
          <TrustedBy />
          {/* <PricingPlans /> */}
          <Testimonials />
          {/* <Blogs /> */}
          <Footer />
        </>
      </div>
    </>
  );
};

export default Homepage;
