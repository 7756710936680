import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ServiceBox = ({ title, paragraph, primaryIcon, secondaryIcon }) => {
  const box_ref = useRef();
  const before_ref = useRef();
  const image_ref = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    box_ref.current.addEventListener("mouseenter", (e) => {
      image_ref.current.src = secondaryIcon;

      gsap.fromTo(
        before_ref.current,
        {
          left: "-100%",
          //   duration: 0.5,
        },
        {
          left: "0%",
          duration: 0.5,
        }
      );
    });
    box_ref.current.addEventListener("mouseleave", () => {
      image_ref.current.src = primaryIcon;

      gsap.fromTo(
        before_ref.current,
        {
          left: "0%",
        },
        {
          left: "100%",
          duration: 0.5,
          onComplete: () => {
            // Set the left property to -100% without animation after completing the mouse leave
            gsap.set(".before", { left: "-100%" });
          },
        }
      );
    });
  }, []);

  useEffect(() => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: box_ref.current,
        start: "top 80%",
        // markers: true,
        stagger: true,
      },
    });

    tl.fromTo(
      box_ref.current,
      {
        top: "10vh",
        ease: "none", // Set easing to linear
        opacity: 0,
      },
      {
        top: "0",
        opacity: 1,
      }
    );
  }, []);

  return (
    <div className="box hoverChangePerspective" ref={box_ref}>
      <div className="before" ref={before_ref}></div>
      <div className="icon">
        <img ref={image_ref} src={primaryIcon} alt="" />
      </div>
      <div className="title">{title}</div>
      <div className="paragraph">{paragraph}</div>
      <button className="redirect_btn">
        Read More
        <span>
          <i className="fa-solid fa-arrow-right"></i>
        </span>
      </button>
    </div>
  );
};

export default ServiceBox;
