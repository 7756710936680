import React from "react";
import "./Footer.css";
import logo from "../../assets/images/logo.png";
import Socials from "../../components/Socials";
import {
  failSwal,
  loadingSwal,
  successSwal,
} from "../../helpers/helperFunctions";
import { newsLetterSubmit } from "../../controllers/rest";

const Footer = () => {
  async function submitNewsLetter(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    loadingSwal("Your request is Loading, Please wait...");
    try {
      const response = await newsLetterSubmit(formData);
      if (response.data.success) {
        e.target.reset();
        successSwal("You have successfully Subscribed to our Newsletter");
      } else {
        failSwal("Something went wrong, Please try again");
      }
    } catch (e) {
      failSwal("Something went wrong, Please try again");
    }
  }

  return (
    <div className="Footer">
      <div className="container">
        <div className="background"></div>
        <div className="overlay"></div>
        <div className="content">
          <div className="details">
            <div className="icon">
              <img src={logo} alt="" />
            </div>
            <div className="paragraph">
              If you have any project in mind, you can contact us.
            </div>
            <div className="news_letter">
              <p className="">Subscribe to our news letter</p>
              <form onSubmit={submitNewsLetter}>
                <input type="email" name="email" placeholder="Email Address" />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>
          <div className="location">
            <h3>Location</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14618.717252962279!2d87.2422681!3d23.651653!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f76b0066f418f3%3A0xe2d2b39e8b1628d8!2sINSABHI%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1712640421473!5m2!1sen!2sin"
              referrerPolicy="no-referrer-when-downgrade"
              title="Insabhi Location"
            ></iframe>
            <div className="resources"></div>
          </div>
          <div className="contact_info">
            <h3>Contact Information</h3>
            <label>
              <i className="fa-regular fa-envelope"></i>
              <a href="mailto:info@insabhi.com">info@insabhi.com</a>
            </label>
            <label>
              <i className="fa-solid fa-location-dot"></i>
              <a
                href="https://maps.app.goo.gl/VMWtaYwhGCMtNAxY6"
                target="_blank"
              >
                Ukhra, West Bengal 713363
              </a>
            </label>
            <label>
              <i className="fa-solid fa-mobile-screen"></i>
              <a href="tel:+917908156500">+917908156500</a>
            </label>
            <label>
              <i className="fa-solid fa-mobile-screen"></i>
              <a href="tel:+919990133483">+919990133483</a>
            </label>
            <Socials />
          </div>
          <div className="bottom">
            <p>Powered by SL TECH ERP SOLUTION</p>
            <p>
              <a href="">Terms Of Use | Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
