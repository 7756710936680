import axios from "axios";
import emailjs from "emailjs-com";

const baseUrlProd = "https://emp.sltecherpsolution.com";
const baseUrlDev = "http://localhost:8071";
const baseUrl = baseUrlProd;

const headers = {
  "Cache-Control": "no-cache",
  "Content-Type": "application/json", // Adjust content type if needed
};

let serviceNumber = "service_4dxhdlc";
let templateNumber = "template_vhdess9";
let publicKey = "olYpnDQbE7De8Ckdh";
export async function sendContactUsFormData(formRef) {
  return await emailjs.sendForm(
    serviceNumber,
    templateNumber,
    formRef.current,
    publicKey
  );
}

export async function careerSubmit(apiBody) {
  return axios.post(baseUrl + "/insabhi/career", apiBody);
}
export async function newsLetterSubmit(apiBody) {
  return axios.post(baseUrl + "/insabhi/news_letter", apiBody);
}
