import React, { useEffect, useState } from "react";
import PageHeaders from "../../components/PageHeaders";
import "./AboutUs.css";
import about1 from "../../assets/images/about-us1.png";
import Footer from "../../Homepage/Footer/Footer";
import {
  addImageAnimation,
  resetImageTransform,
} from "../../helpers/animationHelpers";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  let loading_duration = 3;
  let fade = 1;
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, (loading_duration + fade) * 1000);
  }, []);
  return (
    <>
      {loading && (
        <LoadingComponent loading_duration={loading_duration} fade={fade} />
      )}
      <section className="AboutUs">
        <PageHeaders title="About Us" route="About Us" />
        <div className="container">
          <div className="content">
            <div className="grid_container">
              <div>
                <div class="highlight">About Us</div>
                <div class="heading">Who We Are</div>
              </div>
              <div className="grid">
                <div className="image">
                  <img
                    src={about1}
                    alt=""
                    className="hoverChangePerspective"
                    onMouseMove={addImageAnimation}
                    onMouseLeave={resetImageTransform}
                  />
                </div>
                <div className="para">
                  <p>
                    We are leading provider of Odoo ERP development and
                    consulting services. With a solid track record spanning over
                    two years, Insabhi has established itself as a trusted
                    partner in the software development industry. Our commitment
                    to reliability, efficiency, and trustworthiness sets us
                    apart, making us the preferred choice for businesses
                    worldwide.
                  </p>
                  <br />
                </div>
              </div>
            </div>
            <div className="grid_container">
              <div>
                <div class="highlight">Mission</div>
                <div class="heading">Our Mission</div>
              </div>
              <div className="grid">
                <div className="image">
                  <img
                    src={about1}
                    alt=""
                    className="hoverChangePerspective"
                    onMouseMove={addImageAnimation}
                    onMouseLeave={resetImageTransform}
                  />
                </div>
                <div className="para">
                  <p>
                    At INSABHI, our mission is to empower businesses with
                    comprehensive and seamlessly integrated solutions. Through
                    cutting-edge Odoo ERP software, robust custom software
                    development, and innovative mobile applications, we are
                    dedicated to enhancing efficiency, fostering collaboration,
                    and driving our clients' success. Our commitment to
                    excellence, client-centric approach, and continual
                    innovation position us as the go-to partner for businesses
                    seeking transformative solutions in the ever-evolving
                    technological landscape.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid_container">
              <div>
                <div class="highlight">Vision</div>
                <div class="heading">Our Vision</div>
              </div>
              <div className="grid">
                <div className="image">
                  <img
                    src={about1}
                    alt=""
                    className="hoverChangePerspective"
                    onMouseMove={addImageAnimation}
                    onMouseLeave={resetImageTransform}
                  />
                </div>
                <div className="para">
                  <p>
                    As a trailblazer in the Odoo ERP, software, and mobile
                    application development arena, INSABHI envisions a future
                    where businesses harness the full potential of technology
                    effortlessly. We strive to be the global leader, delivering
                    unmatched value by offering cohesive and adaptive solutions
                    that propel organizations forward. Our vision extends to
                    shaping the future of business management, software
                    development, and mobile app experiences, setting
                    unparalleled standards for innovation, reliability, and
                    client satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default AboutUs;
