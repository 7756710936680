export function addImageAnimation(event) {
  event.stopPropagation();
  if (event.target.className.includes("hoverChangePerspective")) {
    const boundingRect = event.target.getBoundingClientRect();
    const mouseX = event.clientX - boundingRect.left;
    const mouseY = event.clientY - boundingRect.top;
    const imageWidth = boundingRect.width;
    const imageHeight = boundingRect.height;

    // Calculate the percentage of mouse position relative to image width and height
    const percentageX = (mouseX / imageWidth) * 100;
    const percentageY = (mouseY / imageHeight) * 100;

    // Calculate the rotation angles based on the percentages
    const rotateX = (percentageY - 50) * 0.3;
    const rotateY = (percentageX - 50) * 0.3;

    // Apply the transform to the image
    event.target.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${-rotateY}deg) scale3d(1, 1, 1)`;
  }
}

export function resetImageTransform(event) {
  // Reset the transform to its initial state when the mouse leaves
  event.target.style.transform = `perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)`;
  event.target.style.transform = `perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)`;
}
