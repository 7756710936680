import React, { useEffect, useRef, useState } from "react";
import Homepage from "../Homepage/Homepage";
import logo from "../assets/images/logo.png";
import "./Pages.css";
import gsap from "gsap";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import ContactUs from "./ContactUs/ContactUs";
import WorkWithUsPage from "./WorkWithUs/WorkWithUs";
import AboutUs from "./AboutUs/AboutUs";
import Gallery from "./Gallery/Gallery";

const Pages = () => {
  let menuRef = useRef();
  let responsive_overlay = useRef();
  let toggle_btn = useRef();

  const [menuOpen, setMenuOpen] = useState(false);
  const [navClass, setNavClass] = useState("Navbar");

  function toggleMenu() {
    let spans = toggle_btn.current.querySelectorAll("span");

    gsap.to(menuRef.current, {
      left: menuOpen ? "-100%" : "0%",
      duration: menuOpen ? 1 : 0.5,
    });
    gsap.to(responsive_overlay.current, {
      left: menuOpen ? "-110%" : "0%",
      duration: menuOpen ? 1 : 0.5,
    });

    gsap.to(spans[0], {
      left: !menuOpen ? "50%" : "50%",
      top: !menuOpen ? "30%" : "0",
      rotate: !menuOpen ? "225deg" : "0deg",
      duration: !menuOpen ? 1 : 0.5,
      ease: "Power1.easeOut",
    });
    gsap.to(spans[1], {
      opacity: !menuOpen ? "0" : "1",
      duration: !menuOpen ? 1 : 0.5,
      ease: "Power1.easeOut",
    });
    gsap.to(spans[2], {
      rotate: !menuOpen ? "-45deg" : "0deg",
      top: !menuOpen ? "30%" : "66%",
      duration: !menuOpen ? 1 : 0.5,
      ease: "Power1.easeOut",
    });

    setMenuOpen(!menuOpen);
  }
  useEffect(() => {
    let submenus = menuRef.current.querySelectorAll(".has-sub-menu");
    submenus.forEach((element) => {
      element.addEventListener("click", (e) => {
        let item = element.querySelector(".sub-menu");
        // Toggle the height value
        const newHeight = item.clientHeight === 0 ? 300 : 0;
        if (window.innerWidth < 1200) {
          gsap.to(item, {
            height: newHeight,
            duration: 0.1,
            ease: "power1.inOut",
          });
        }
      });
    });
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 50) {
        setNavClass("Navbar scrolled");
      } else {
        setNavClass("Navbar");
      }
    });
  }, []);
  return (
    <div className="page">
      <div className={navClass}>
        <div className="container">
          <nav className="menu-navbar">
            <div className="header-logo">
              <NavLink className="logo-link" to="#">
                <img
                  className="logo-img"
                  loading="lazy"
                  src={logo}
                  alt="logo"
                />
              </NavLink>
            </div>
            <div ref={menuRef} className="links menu-wrapper ">
              <ul className="list-js links-list">
                <li className="menu-item">
                  <NavLink onClick={toggleMenu} className="menu-link" to="/">
                    home
                  </NavLink>
                  {/* <ul className="sub-menu ">
                    <li className="menu-item sub-menu-item">
                      <NavLink onClick={()=>setMenuOpen(false)} className="menu-link sub-menu-link  " to="./">
                        home 1
                      </NavLink>
                    </li>
                    <li className="menu-item sub-menu-item">
                      <NavLink
                        className="menu-link sub-menu-link  "
                        to="home-2.html"
                      >
                        home 2
                      </NavLink>
                    </li>
                    <li className="menu-item sub-menu-item">
                      <NavLink
                        className="menu-link sub-menu-link  "
                        to="home-3.html"
                      >
                        home 3 [ Slider Style 1 ]
                      </NavLink>
                    </li>
                    <li className="menu-item sub-menu-item">
                      <NavLink
                        className="menu-link sub-menu-link  "
                        to="home-4.html"
                      >
                        home 4 [ Slider Style 2 ]
                      </NavLink>
                    </li>
                    <li className="menu-item sub-menu-item">
                      <NavLink
                        className="menu-link sub-menu-link  "
                        to="home-rtl.html"
                      >
                        home [ RTL languages ]
                      </NavLink>
                    </li>
                  </ul> */}
                </li>
                <li className="menu-item">
                  <NavLink
                    onClick={toggleMenu}
                    className="menu-link  "
                    to="/aboutus"
                  >
                    About Us
                  </NavLink>
                  {/* <ul className="sub-menu ">
                    <li className="menu-item sub-menu-item">
                      <NavLink onClick={()=>setMenuOpen(false)} className="menu-link sub-menu-link  " to="./">
                        home 1
                      </NavLink>
                    </li>
                    <li className="menu-item sub-menu-item">
                      <NavLink
                        className="menu-link sub-menu-link  "
                        to="home-2.html"
                      >
                        home 2
                      </NavLink>
                    </li>
                    <li className="menu-item sub-menu-item">
                      <NavLink
                        className="menu-link sub-menu-link  "
                        to="home-3.html"
                      >
                        home 3 [ Slider Style 1 ]
                      </NavLink>
                    </li>
                    <li className="menu-item sub-menu-item">
                      <NavLink
                        className="menu-link sub-menu-link  "
                        to="home-4.html"
                      >
                        home 4 [ Slider Style 2 ]
                      </NavLink>
                    </li>
                    <li className="menu-item sub-menu-item">
                      <NavLink
                        className="menu-link sub-menu-link  "
                        to="home-rtl.html"
                      >
                        home [ RTL languages ]
                      </NavLink>
                    </li>
                  </ul> */}
                </li>
                <li className="menu-item">
                  <NavLink
                    onClick={toggleMenu}
                    className="menu-link  "
                    to="/contactus"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    onClick={toggleMenu}
                    className="menu-link  "
                    to="/gallery"
                  >
                    Gallery
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    onClick={toggleMenu}
                    className="menu-link  "
                    to="/career"
                  >
                    Career
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="controls-box">
              {/* <!--Menu Toggler button--> */}
              <div
                onClick={toggleMenu}
                ref={toggle_btn}
                className="control  menu-toggler"
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* <!--search Icon button--> */}
              {/* <div className="control header-search-btn">
                <i className="fas fa-search"></i>{" "}
              </div> */}
              {/* <!--Dark/Light mode button--> */}

              {/* <!--mini shoping cart--> */}
            </div>
            <div
              onClick={toggleMenu}
              ref={responsive_overlay}
              className="responsive_overlay"
            ></div>
          </nav>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<Homepage setMenuOpen={setMenuOpen} />} />
        <Route
          path="/contactus"
          element={<ContactUs setMenuOpen={setMenuOpen} />}
        />
        <Route
          path="/career"
          element={<WorkWithUsPage setMenuOpen={setMenuOpen} />}
        />
        <Route
          path="/aboutus"
          element={<AboutUs setMenuOpen={setMenuOpen} />}
        />
        <Route
          path="/gallery"
          element={<Gallery setMenuOpen={setMenuOpen} />}
        />
      </Routes>
    </div>
  );
};

export default Pages;
