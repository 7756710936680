import React from "react";
import "./Overview.css";
import bulbIcon from "../../assets/images/bulb_icon.png";
import job from "../../assets/images/job.png";
import aim from "../../assets/images/aim.png";
import person from "../../assets/images/person.png";
import OverviewBox from "../../components/OverviewBox";
const Overview = () => {
  return (
    <div className="Overview">
      <div className="overview_content">
        <div className="overview_bg"></div>
        <div className="overview_container">
          <OverviewBox icon={bulbIcon} number="200" text="Finished Projects" />
          <OverviewBox icon={job} number="150" text="Jobs Created" />
          <OverviewBox icon={person} number="200" text="Happy Customers" />
          <OverviewBox icon={aim} number="20" text="Years Of Experience" />
        </div>
      </div>
    </div>
  );
};

export default Overview;
