import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import aws from "../assets/images/technologies/aws.png";
import codeigniter from "../assets/images/technologies/codeigniter.png";
import django from "../assets/images/technologies/django.png";
import Docker from "../assets/images/technologies/Docker.png";
import flutter from "../assets/images/technologies/flutter.png";
import js from "../assets/images/technologies/js.jpeg";
import Mongodb from "../assets/images/technologies/Mongodb.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";

// import required modules

export default function WorkWithUsSwiper() {
  const [slidesPerView, setSlidesPerView] = useState(
    window.innerWidth > 991 ? 6 : 3
  );

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(window.innerWidth > 991 ? 6 : 3);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let images = [codeigniter, django, Docker, flutter, js, Mongodb];

  return (
    <>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // navigation={true}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {images.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="swiper_img_container">
                <img className="" src={item} alt="" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
